import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';
import { injectI18n } from 'nordic/i18n';

import Action from '../../../commons/action';
import Section from '../../../commons/section';
import CardGrid from '../../../commons/card/card-grid';
import withTracker from '../../../commons/with-tracker';
import divideByGroupsOf from '../divide-groups';

const categoriesByColumn = 3;
const categoriesByRow = 4;

// Assigning these propTypes to a variable let us add an `isRequired` on each use so eslint understands what we mean.
const elementsPropType = arrayOf(
  shape({
    target: string.isRequired,
    title: string.isRequired,
  }),
);

function CategoriesColumn({ elements, deviceType }) {
  const missingBlocks = categoriesByColumn - elements.length;
  const fillerElements = Array.from({ length: missingBlocks }, (_, index) => (
    <div key={index} className="filler" />
  ));
  return (
    <div className="category-column">
      {elements.map(item => <CardGrid key={item.id} {...{ item }} deviceType={deviceType} />)}
      {fillerElements}
    </div>
  );
}

CategoriesColumn.propTypes = {
  elements: elementsPropType.isRequired,
  deviceType: string,
};

function CategoriesDesktop({ title, elements, type, view_more_action, deviceType }) {
  const items = divideByGroupsOf(elements, categoriesByColumn);
  return (
    <Section type={type}>
      <div className="row container">
        <div className="wrapper">
          <div className="section-header">
            <h2>{title}</h2>
            <Action {...view_more_action} id={null} />
          </div>
          <CarouselSnapped
            slidesPerView={categoriesByRow}
            spacing={20}
            pagination={{ position: 'top', mode: 'light' }}
          >
            {items.map(item => (
              <CarouselSnappedSlide key={item.id}>
                <CategoriesColumn {...item} deviceType={deviceType} />
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        </div>
      </div>
    </Section>
  );
}

CategoriesDesktop.propTypes = {
  elements: elementsPropType.isRequired,
  title: string.isRequired,
  type: string.isRequired,
  deviceType: string,
  view_more_action: shape(Action.propTypes),
};

export default injectI18n(withTracker(CategoriesDesktop));
